import FaLinkedin from "../SVG/Fontawesome/FaLinkedin";
import FaYoutube from "../SVG/Fontawesome/FaYoutube";
import FaFacebook from "../SVG/Fontawesome/FaFacebook";
import FaTelegram from "../SVG/Fontawesome/FaTelegram";
import FaWhatsapp from "../SVG/Fontawesome/FaWhatsapp";
import LinkWrapper from "../LinkWrapper";

const FooterSocials = () => {
  return (
    <section className="flex flex-wrap items-center justify-center pt-4 pb-4 gap-8 text-xl mb-0">
      <a id="linkedin" href="https://www.linkedin.com/company/doubtnut/" target={"_blank"} className="rounded-full border-2 border-white text-white w-10 h-10 flex justify-center items-center" rel="noreferrer noopener">
        <FaLinkedin />
      </a>
      <a id="youtube" href="https://www.youtube.com/channel/UCcv7pspGHmM7AOywuLM1ufA" target={"_blank"} className="rounded-full border-2 border-white text-white w-10 h-10 flex justify-center items-center" rel="noreferrer noopener">
        <FaYoutube />
      </a>
      <a id="facebook" href="https://www.facebook.com/doubtnut" target={"_blank"} className="rounded-full border-2 border-white text-white w-10 h-10 flex justify-center items-center" rel="noreferrer noopener">
        <FaFacebook />
      </a>
      <LinkWrapper id="telegram" href="/telegram-study-channels-doubtnut" className="rounded-full border-2 border-white text-white w-10 h-10 flex justify-center items-center" rel="noreferrer">
        <FaTelegram />
      </LinkWrapper>
      <LinkWrapper id="whatssapp" href="/whatsapp-group" className="rounded-full border-2 border-white text-white w-10 h-10 flex justify-center items-center" rel="noreferrer">
        <FaWhatsapp />
      </LinkWrapper>
    </section>
  );
}

export default FooterSocials;
