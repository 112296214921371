export const FooterLinksList: any = [
  {
    "Exams": [
      { title: "IIT JEE", link: "/iit-jee" },
      { title: "NEET", link: "/neet" },
      { title: "UP Board", link: "/up-board" },
      { title: "Bihar Board", link: "/bihar-board" },
      { title: "CBSE", link: "/cbse" },
    ]
  },
  {
    "Free Textbook Solutions": [
      { title: "KC Sinha Solutions for Maths", link: "/books/class-12-kc-sinha-english-maths-english-medium-in-english-download-questions-answers-solutions" },
      { title: "Cengage Solutions for Maths", link: "/books/class-12-cengage-english-maths-english-medium-in-english-download-questions-answers-solutions" },
      { title: "DC Pandey Solutions for Physics", link: "/books/class-12-dc-pandey-english-physics-english-medium-in-english-download-questions-answers-solutions" },
      { title: "HC Verma Solutions for Physics", link: "/books/class-12-hc-verma-english-physics-english-medium-in-english-download-questions-answers-solutions" },
      { title: "Sunil Batra Solutions for Physics", link: "/books/class-12-sunil-batra--41-years-iitjee-physics--physics-english-medium-in-hindi-download-questions-answers-solutions" },
      { title: "Pradeep Solutions for Physics", link: "/books/class-12-pradeep-physics-english-medium-in-hindi-download-questions-answers-solutions" },
      { title: "Errorless Solutions for Physics", link: "/books/class-12-p-bahadur-chemistry-english-medium-in-hindi-download-questions-answers-solutions" },
      { title: "Narendra Awasthi Solutions for Chemistry", link: "/books/class-12-narendra-awasthi-chemistry-english-medium-in-hindi-download-questions-answers-solutions" },
      { title: "MS Chouhan Solutions for Chemistry", link: "/books/class-12-ms-chouhan-chemistry-english-medium-in-hindi-download-questions-answers-solutions" },
      { title: "Errorless Solutions for Biology", link: "/books/class-12-errorless-hindi-biology-hindi-medium-download-questions-answers-solutions" },
    ],
    "Free Ncert Solutions English Medium": [
      { title: "NCERT Solutions", link: "/ncert-solutions" },
      { title: "NCERT Solutions for Class 12 English Medium", link: "/class-12/maths" },
      { title: "NCERT Solutions for Class 11 English Medium", link: "/class-11/maths" },
      { title: "NCERT Solutions for Class 10 English Medium", link: "/class-10/maths" },
      { title: "NCERT Solutions for Class 9 English Medium", link: "/class-9/maths" },
      { title: "NCERT Solutions for Class 8 English Medium", link: "/class-8/maths" },
      { title: "NCERT Solutions for Class 7 English Medium", link: "/class-7/maths" },
      { title: "NCERT Solutions for Class 6 English Medium", link: "/class-6/maths" },
    ],
    "Free Ncert Solutions Hindi Medium": [
      { title: "NCERT Solutions", link: "/ncert-solutions" },
      { title: "NCERT Solutions for Class 12 Hindi Medium", link: "/class-12/maths-hindi-medium" },
      { title: "NCERT Solutions for Class 11 Hindi Medium", link: "/class-11/maths-hindi-medium" },
      { title: "NCERT Solutions for Class 10 Hindi Medium", link: "/class-10/maths-hindi-medium" },
      { title: "NCERT Solutions for Class 9 Hindi Medium", link: "/class-9/maths-hindi-medium" },
      { title: "NCERT Solutions for Class 8 Hindi Medium", link: "/class-8/maths-hindi-medium" },
      { title: "NCERT Solutions for Class 7 Hindi Medium", link: "/class-7/maths-hindi-medium" },
      { title: "NCERT Solutions for Class 6 Hindi Medium", link: "/class-6/maths-hindi-medium" },
    ]
  },
  {
    "Boards": [
      { title: "CBSE", link: "/cbse" },
      { title: "UP Board", link: "/up-board" },
      { title: "Bihar Board", link: "/bihar-board" },
      { title: "UP Board", link: "/up-board" },
    ]
  },
  {
    "Resources": [
      { title: "Unit Converters", link: "/unit-converter" },
      { title: "Calculators", link: "/calculator" },
      { title: "Books Store", link: "https://store.doubtnut.com" },
      { title: "Seminar", link: "/seminar" },
      { title: "Results", link: "/results" },
      { title: "Search Doubtnut", link: "/search" },
      { title: "Blogs", link: "/blogs" },
      { title: "Class 12 All Books", link: "/books/class-12-all-books-download-questions-answers-solutions" },
      { title: "Class 11 All Books", link: "/books/class-11-all-books-download-questions-answers-solutions" },
      { title: "Class 10 All Books", link: "/books/class-10-all-books-download-questions-answers-solutions" },
      { title: "Class 9 All Books", link: "/books/class-9-all-books-download-questions-answers-solutions" },
      { title: "Class 8 All Books", link: "/books/class-8-all-books-download-questions-answers-solutions" },
      { title: "Class 7 All Books", link: "/books/class-7-all-books-download-questions-answers-solutions" },
      { title: "Class 6 All Books", link: "/books/class-6-all-books-download-questions-answers-solutions" }
    ]
  }
]